<template>
    <el-drawer title="催款" :visible.sync="drawer" direction="rtl" destroy-on-close custom-class="Receipt" size="600px" :append-to-body="true">
        <div class="container">
            <div class="remark">
                <div class="title">催款描述</div>
                <textarea class="inp" type="text" v-model="txtDes" placeholder="尊敬的客户，您本月账单已逾期，如已支付请忽略。"></textarea>
                <el-button style="float:right;margin-top:8px" @click="handleCommand">微信分享</el-button>
            </div>
            <div>
                <el-table :data="tableData" style="width: 100%" :max-height="height" v-loading="loading">
                    <el-table-column prop="CreateTime" label="日期">
                        <template slot-scope="scope">
                            {{ scope.row.CreateTime?.substring(0, 10) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="OverdueDay" label="逾期天数"> </el-table-column>
                    <el-table-column prop="TotalSum" label="逾期金额">
                        <template slot-scope="scope">
                            <!-- {{ toThousands(scope.row.TotalSum - scope.row.CollectionSum) }} -->
                            {{ toThousands(scope.row.Money) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="170" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" @click="handleView(scope.row)">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total"
                    :current-page="pagination.current" :page-size="pagination.pageSize"
                    :page-sizes="pagination.pageSizeOptions" style="text-align: right; margin-top: 8px; padding: 0"
                    @current-change="currentChange" @size-change="handleSizeChange">
                </el-pagination>
            </div>
            <Form ref="Form" :parentObj="{ ...this }" />
            <ReceiptPage ref="receiptPage" :parentObj="{ ...this }" /> 
            <!-- 微信分享 -->
            <el-dialog title="请使用微信扫一扫" :visible.sync="dialogVisible" width="300px" :append-to-body="true">
                <div style="display: flex; justify-content: center">
                    <vue-qr :logoSrc="require('@/assets/logo.png')" :text="codeValue" :logoScale="0.2" :size="200"></vue-qr>
                </div>
            </el-dialog>
        </div>
    </el-drawer>
</template>
  
<script>
import wx from "weixin-js-sdk";
import { getMonthRange, toThousands } from '../../utils/Tools';
import Form from "./Form.vue"
import ReceiptPage from "./Receipt.vue"
import QRCode from "qrcodejs2";
import VueQr from "vue-qr";
export default {
    components: {
        Form,
        ReceiptPage,
        VueQr,
        // VueBarcode,
    },
    data() {
        return {
            data: [],
            codeValue: "",
            entity: {},
            href: "",
            drawer: false,
            getMonthRange,
            toThousands,
            pagination: {
                current: 1,
                pageSize: 25,
                total: 0,
                pageSizeOptions: [25, 50, 100],
            },
            sorter: {
                field: "CreateTime",
                order: "desc",
            },
            tableData: [],
            loading: false,
            row: {},
            callForPayDetail: {},
            querayPamras: {
                Search: {
                    Condition: "",
                    Keyword: "",
                    CompanyId: "",
                    CustomerId: "",
                    Time: "",
                    // 预期天数
                    OverdueDay: ""
                }
            },
            dialogVisible:false,
            txtDes:'',
            days:''
        };
    },
    computed: {
        height() {
            return document.documentElement.clientHeight * 0.75;
        },
    },
    methods: {
        openForm(data) { 
            this.drawer = true
            this.callForPayDetail = data
            this.querayPamras.Search.CompanyId = this.callForPayDetail.CompanyId
            this.querayPamras.Search.CustomerId = this.callForPayDetail.CustomerId
            this.querayPamras.Search.Time = (this.callForPayDetail.CreateTime).substring(0, 10)
            this.querayPamras.Search.OverdueDay = this.callForPayDetail.OverdueDay
            this.querayPamras.Search.Condition = this.callForPayDetail.Id
            this.getDataList();
        },
        // 微信分享
        handleCommand() {
            let _url = location.href.split("#")[0] + "#/App/Reconciliation?data=";
            _url += JSON.stringify({
                CompanyId: this.querayPamras.Search.CompanyId,
                Id: this.querayPamras.Search.Condition,
                CustomerId: this.querayPamras.Search.CustomerId,
                days: this.days,
                token: sessionStorage.getItem('token'),
                title:this.txtDes
            })
            this.codeValue=_url
            console.log("=========================",this.codeValue)
            this.dialogVisible = true;
        },
        currentChange(page) {
            this.pagination.current = page;
            this.getDataList();
        },
        handleSizeChange(lint) {
            this.pagination.pageSize = lint;
            this.getDataList();
        },
        handleView(row) {
            // let url = `${location.href.split("#")[0]}#/App/Receipt?Id=${row.Id}`;
            // window.open(url, "_blank");
            console.log("================" + JSON.stringify(row));
            const loading = this.$loading({
                lock: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
                });
            this.$http
                .post("/BO/BIlling/GetOrderReturnId?Id=" + row.Id)
                .then((res) => {
                if (res.Success) {
                if(res.Data.Type ==0 ){
                    console.log('打开小票页面')
                    // 打开小票页面
                    this.$refs.receiptPage.openForm(res.Data.Id);
                    setTimeout(() => {
                        loading.close();
                    }, 400);
                    }else{
                    // 打开回执单页面
                    this.$refs.Form.openForm(res.Data.Id);
                    setTimeout(() => {
                        loading.close();
                    }, 2500);
                    }
                } else {
                    setTimeout(() => {
                        loading.close();
                    }, 2500);
                }
                });
        },
        getDataList() {
            this.loading = true;
            // console.log(">>>>>>>>>>>>>>>>>>>>>"+this.querayPamras)
            this.$http
                .post("/BO/BIlling/GetHistoryBIllingOverdueList", {
                    PageIndex: this.pagination.current,
                    PageRows: this.pagination.pageSize,
                    SortField: "CreateTime",
                    SortType: this.sorter.order,
                    Search: this.querayPamras.Search,
                })
                .then((resJson) => {
                    this.loading = false;
                    if (resJson.Success) {
                        resJson.Data.forEach(item => {
                            item.Money = toThousands(item.Money)
                            item.Owing = toThousands(item.Owing)
                            this.days = item.OverdueDay
                        })
                        this.tableData = resJson.Data;
                        if (this.tableData) {
                            const pagination = { ...this.pagination };
                            pagination.total = resJson.Total;
                            this.pagination = pagination;
                        }
                    }
                });
        },
    },
};
</script>
  
<style lang="less" scoped>
.remark {
    overflow: hidden;
    background: rgba(51, 51, 51, 0.1);
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px
}

.title {
    color: #333;
    font-size: 13px;
}

.inp {
    display: block;
    width: 100%;
    margin-top: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-left: 4px;
    padding-right: 4px;
}

.fixBtn {
    position: fixed;
    right: 20px;
    bottom: 20px;
}

.Receipt {
    .container {
        margin: 0 auto;
        color: #000;
        min-width: 216px;
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;

        h3 {
            text-align: center;
        }

        .cell {
            display: flex;
            line-height: 2;

            span {
                width: 40px;
            }
        }

        .tableRow {
            font-size: 12px;
            display: flex;
            align-items: center;

            span {
                overflow: hidden;
            }

            .tableCol {
                text-align: right;
                width: 75px;
                overflow: hidden;
            }

            .left {
                text-align: left;
                flex: 1;
            }

            .tableIndex {
                width: 25px;
            }

            .col2 {
                width: 150px;
            }
        }
    }
}
</style>